import React from "react";
import { IChildren } from "@components/interfaces/IChildren";
import "./Stack.scss";

type JustifyContent = "flex-start" | "center" | "flex-end" | "space-around" | "space-between" | "space-evenly";
type AlignItems = "flex-start" | "center" | "flex-end";

export interface IStack {
  colGap?: number;
  rowGap?: number;
  fullSize?: boolean;
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  className?: string;

  onMouseEnter?(): void;

  onMouseLeave?(): void;

  onClick?(): void;

  onBlur?(): void;
}

const Stack = ({
  rowGap = 0,
  colGap = 0,
  fullSize = false,
  children,
  justifyContent = "flex-start",
  alignItems = "flex-start",
  className = "",
  onMouseEnter,
  onMouseLeave,
  onClick,
  onBlur
}: IStack & IChildren) => {
  const classes = `stack${className ? ` ${className}` : ""}${fullSize ? " full-size" : ""}`;
  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onBlur={onBlur}
      className={classes}
      style={{
        gap: `${rowGap}px ${colGap}`,
        justifyContent,
        alignItems
      }}
    >
      {children}
    </div>
  );
};

export default Stack;
