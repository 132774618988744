import { EffectsProvider } from "@contexts/Effects";
import { PageDimensionsProvider } from "@contexts/PageHeight";
import { TabsProvider } from "@pages/CaseStudy/shared/TabsContext";
import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";

const rootElement = document.getElementById("root");
const ReactRoot = ReactDOM.createRoot(rootElement!);

ReactRoot.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);
