import React from "react";
import { IChildren } from "@components/interfaces/IChildren";
import "./Group.scss";
import { BaseProps } from "@components/interfaces/BaseProps";

export type IGroup = {
  colGap?: number;
  rowGap?: number;
  justifyContent?: "flex-start" | "center" | "flex-end" | "space-around" | "space-between" | "space-evenly";
  alignItems?: "flex-start" | "center" | "flex-end";
  className?: string;
  grid?: boolean;
  fullSize?: boolean;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
} & BaseProps;

const Group = ({
  colGap,
  rowGap,
  children,
  justifyContent = "flex-start",
  alignItems = "center",
  grid = false,
  fullSize = false,
  className = "",
  onMouseEnter,
  onMouseLeave,
  ...rest
}: IGroup & IChildren) => {
  const classes = `${className ? ` ${className}` : ""}${grid ? " grid full-size" : ""}${
    fullSize && !grid ? " full-size" : ""
  } group`;
  return (
    <div
      className={classes}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        gap: `${rowGap ? rowGap : `0`}px ${colGap ? colGap : "0"}px`,
        justifyContent,
        alignItems,
        ...rest.style
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Group;
