import React from "react";
import "./SuspenseLoading.scss";

const SuspenseLoading = () => {
  return (
    <div className="suspense-loading">
      <img src="/images/logos/go2nft-pure-logo.svg" className="logo-animation" width={80} />
    </div>
  );
};

export default SuspenseLoading;
