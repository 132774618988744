import React from "react";
import { useNavigationCtx } from "@contexts/NavigationContext";
import Group from "@components/arrangement/Group/Group";
import NavLink, { NavLinkProps } from "@components/navigation/NavMenu/NavLink";
import "./Navigation.scss";

const Navigation = () => {
  const { navigation } = useNavigationCtx();

  return (
    <Group className="navigation" colGap={24}>
      {navigation.map((nav: NavLinkProps, index: number) => (
        <NavLink {...nav} key={index} />
      ))}
    </Group>
  );
};

export default Navigation;
