import React, { useEffect } from "react";
import classNames from "classnames";

import { useCollapseComp } from "../Collapse";

interface CollapseContentProps {
  children: React.ReactNode;
  className?: string;
  onExpand?(): void;
}

const CollapseContent = ({ children, className = "", onExpand }: CollapseContentProps) => {
  const { tagRef, contentHeight, isExpanded } = useCollapseComp();

  useEffect(() => {
    // setTimeout(() => {
    //   window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    // }, 250);
    if (isExpanded) {
      // onExpand && onExpand();
    }
  }, [isExpanded]);

  const classes = classNames("collapse-content", className);

  return (
    <div style={{ height: contentHeight }} className={classes}>
      <div ref={tagRef}>{children}</div>
    </div>
  );
};

export default CollapseContent;
