import { Routing } from "src/router/router";

import "@scss/responsive.scss";
import "@scss/utils.scss";
import "@scss/base.scss";
import "@scss/lib.scss";
import "@scss/grid.scss";
import "@scss/animations.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./features/languages/i18n";

const App = () => {
  return <Routing />;
};

export default App;
