import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import LayoutNavMainFooter from "@components/layoutElements/Layout/Layout1/Layout1";
import NoLayout from "@components/layoutElements/Layout/NoLayout/NoLayout";
import SuspenseLoading from "@components/ux/SuspenseLoading/SuspenseLoading";

import { paths } from "@data/paths";

import { EffectsProvider } from "@contexts/Effects";
import { NavigationProvider } from "@contexts/NavigationContext";
import { NavLogoManagerProvider } from "@contexts/NavLogoManager";
import { PageDimensionsProvider } from "@contexts/PageHeight";
import { FormProvider } from "@pages/Business/context/FormContext";
import { TabsProvider } from "@pages/CaseStudy/shared/TabsContext";

// import Blog from "@pages/Blog/Blog";
// import Article from "@pages/Blog/components/Article/Article";
import TaskManager from "@pages/shared/TaskManager/TaskManager";
import Download from "@pages/Download/Download";
import Development from "@pages/Development/Development";
// import Brands from "@pages/Brands/Brands";

const Main = React.lazy(() => import("@pages/Main/Main"));
const CaseStudy = React.lazy(() => import("@pages/CaseStudy/CaseStudy"));
const SportLifestyle = React.lazy(() => import("@pages/CaseStudy/SportLifestyle/SportLifestyle"));
const Fashion = React.lazy(() => import("@pages/CaseStudy/Fashion/Fashion"));
const Beauty = React.lazy(() => import("@pages/CaseStudy/Beauty/Beauty"));
const Brands = React.lazy(() => import("@pages/Brands/Brands"));
const Partners = React.lazy(() => import("@pages/Partners/Partners"));
const Business = React.lazy(() => import("@pages/Business/Business"));
const NFT = React.lazy(() => import("@pages/NFT/NFT"));
const Campaign = React.lazy(() => import("@pages/Campaign/Campaign"));
const Pricing = React.lazy(() => import("@pages/Pricing/Pricing"));
const NotFound = React.lazy(() => import("@pages/NotFound/NotFound"));

export const Routing = () => {
  return (
    <BrowserRouter>
      <PageDimensionsProvider>
        <EffectsProvider>
          <NavLogoManagerProvider>
            <NavigationProvider>
              <Routes>
                <Route
                  path={paths.main.path}
                  element={
                    <React.Suspense fallback={<SuspenseLoading />}>
                      <LayoutNavMainFooter>
                        <Main />
                      </LayoutNavMainFooter>
                    </React.Suspense>
                  }
                />
                <Route
                  path={paths.caseStudy.path}
                  element={
                    <TabsProvider>
                      <React.Suspense fallback={<SuspenseLoading />}>
                        <LayoutNavMainFooter>
                          <CaseStudy />
                        </LayoutNavMainFooter>
                      </React.Suspense>
                    </TabsProvider>
                  }
                >
                  <Route
                    path={paths.lifestyle.path}
                    element={
                      <React.Suspense fallback={<SuspenseLoading />}>
                        <SportLifestyle />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path={paths.fashion.path}
                    element={
                      <React.Suspense fallback={<SuspenseLoading />}>
                        <Fashion />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path={paths.beauty.path}
                    element={
                      <React.Suspense fallback={<SuspenseLoading />}>
                        <Beauty />
                      </React.Suspense>
                    }
                  />
                </Route>
                {/* <Route
                    path={`${paths.blog.path}`}
                    element={
                      <LayoutNavMainFooter>
                      <Blog />
                      </LayoutNavMainFooter>
                    }
                    >
                    <Route path={paths.blogArticle.path} element={<Article />} />
                  </Route> */}
                <Route
                  path={paths.brands.path}
                  element={
                    <React.Suspense fallback={<SuspenseLoading />}>
                      {/* <LayoutNavMainFooter> */}
                      <Brands />
                      {/* </LayoutNavMainFooter> */}
                    </React.Suspense>
                  }
                />
                <Route
                  path={paths.partners.path}
                  element={
                    <React.Suspense fallback={<SuspenseLoading />}>
                      <LayoutNavMainFooter>
                        <Partners />
                      </LayoutNavMainFooter>
                    </React.Suspense>
                  }
                />
                <Route
                  path={paths.business.path}
                  element={
                    <React.Suspense fallback={<SuspenseLoading />}>
                      <LayoutNavMainFooter>
                        <FormProvider>
                          <Business />
                        </FormProvider>
                      </LayoutNavMainFooter>
                    </React.Suspense>
                  }
                />
                <Route
                  path={paths.pricing.path}
                  element={
                    <React.Suspense fallback={<SuspenseLoading />}>
                      <LayoutNavMainFooter>
                        <Pricing />
                      </LayoutNavMainFooter>
                    </React.Suspense>
                  }
                />
                <Route
                  path={`${paths.nft.path}/:uuid`}
                  element={
                    <NoLayout>
                      <NFT />
                    </NoLayout>
                  }
                />
                <Route
                  path={`${paths.campaign.path}/:cid`}
                  element={
                    <NoLayout>
                      <Campaign />
                    </NoLayout>
                  }
                />
                <Route
                  path={paths.development.path}
                  element={
                    <NoLayout>
                      <Development />
                    </NoLayout>
                  }
                />
                <Route
                  path={paths.download.path}
                  element={
                    <NoLayout>
                      <Download />
                    </NoLayout>
                  }
                />
                <Route
                  path={paths.notFound.path}
                  element={
                    <React.Suspense fallback={<SuspenseLoading />}>
                      <NoLayout>
                        <NotFound />
                      </NoLayout>
                    </React.Suspense>
                  }
                />
              </Routes>
              <TaskManager />
            </NavigationProvider>
          </NavLogoManagerProvider>
        </EffectsProvider>
      </PageDimensionsProvider>
    </BrowserRouter>
  );
};
